import React, { Component } from "react";

import App from './App';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from "./theme/theme";


class AppWrapper extends Component {
    constructor(props) {
        super(props);
        console.log(this)

    }

    render() {
        return (
            <React.Fragment>

                <MuiThemeProvider theme={theme}>
                    <App />
                </MuiThemeProvider>

            </React.Fragment>

        );
    }
}


//export default App;
export default AppWrapper;


