import { create } from 'apisauce'
const Authorization = "Basic MTIzNDEyMzQxMjM0MTIzNDo1NzM1NzI3OTY0NDExMjczOTg5MTM0OTU3ODk4Njg0NjgyMDY0ODE=";

// const clientID = process.env.REACT_APP_API_CLIENT_ID;
// const clientSecret = process.env.REACT_APP_API_CLIENT_SECRET;

// const signature = clientID + ":" + clientSecret;
// const base64Signature = btoa(signature);

// const Authorization = 'Basic ' + base64Signature;
const URL = "https://ff-int.api.wexer.com";
//const TenantId = 'wexerdemo'

const testApi = create({
    baseURL: URL,
    headers: {
        'Authorization': Authorization,
        'content-type': 'application/json',
        'TenantId': localStorage.getItem('TenantId'),
        'Cache-Control': 'no-cache'
    }
});



const stageApi = create({

    baseURL: "https://stage.api.wexer.com",
    headers: {
        'Authorization': Authorization,
        'content-type': 'application/json',
        'TenantId': localStorage.getItem('TenantId'),
        'Cache-Control': 'no-cache'
    }
});

const prodApi = create({
    baseURL: "https://api.wexer.com/",
    headers: {
        'Authorization': "Basic YTBkZGY0ZGM1YjBiNGI2MjhlNWE0YzI3YjkzYzk4OGU6NzExZmYwMTAwMzc2NDg4OTk4NzE1OGIzMjljN2U2YzA=",
        'content-type': 'application/json',
        'TenantId': localStorage.getItem('TenantId'),
        'Cache-Control': 'no-cache'
    }
});


const ENVS = {
    test: testApi,
    stage: stageApi,
    prod: prodApi
}

export default ENVS;