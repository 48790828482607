import React from "react";
import ReactDOM from "react-dom";
import "./theme/styles/index.scss";
import App from "./AppWrapper";
import registerServiceWorker from "./registerServiceWorker";
import { IntlProvider, addLocaleData } from "react-intl";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import locale_en from "react-intl/locale-data/en";
import locale_de from "react-intl/locale-data/de";
import { flattenMessages } from "./utils/flattenMessages";
import authReducer from "./store/reducers/auth";
import subscriptionReducer from "./store/reducers/subscription";
import {
  GetTranslationData,
  checkLocalStorage,
  GetConfigData
} from "./store/actions/ClientSettings";

const config = require('./assets/config.json')

registerServiceWorker();

const URL =
  window.location.search && window.location.search !== ""
    ? window.location.search
    : sessionStorage.getItem("embededConfig");

let embededConfig = {};
if (URL && URL !== "" && URL.includes("runtime=embedded")) {
  sessionStorage.setItem("embededConfig", URL);
  let queryParamsArray = URL.slice(1)
    .split("&")
    .map((str) => str.split("="));
  for (let i = queryParamsArray.length; i--;) {
    embededConfig[queryParamsArray[i][0]] = queryParamsArray[i][1];
  }

  if (embededConfig["lang"]) {
    config["language"] = embededConfig["lang"];
  }
}
addLocaleData([...locale_en, ...locale_de]);
checkLocalStorage();
var clientData = GetConfigData();
const language = clientData.language;
const message = GetTranslationData(clientData.name, language);






const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ != undefined
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose
    : null || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  subscription:subscriptionReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);


const app = (
  <Provider store={store}>
    <IntlProvider
      locale={"en"}
      messages={flattenMessages(message[language])}
      defaultLocale="en"
      defaultMessages={flattenMessages(message["en"])}
    >
      <App embededConfig={embededConfig} />
    </IntlProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));