import React, { Component } from "react";
import PageTitle from "../../components/PageTitle";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import Button from "@material-ui/core/Button";
import Spinner from "../../components/spinner/spinner";
import { connect } from "react-redux";
import {
  ForgetPasswordRequest,
  ResetPasswordRequest,
} from "../../store/actions/auth";
import Logo from "../SignIn/Logo";

import { ToastContainer, toast,Slide } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const tenantSepecificUIObject = require("../../assets/ClubManager/tenantConfig.json");
const FORM = tenantSepecificUIObject.forgetPassword[0];

let resetEmail = "";
let resetToken = "";

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token != "",
    sendMailerrorMessage: state.auth.sendMailerrorMessage,
    resetPassworderrorMessage: state.auth.resetPassworderrorMessage,
    resetPasswordSuccessMessage: state.auth.resetPasswordSuccessMessage,
    ForgotEmailSent: state.auth.ForgotEmailSent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onforgetPasswordRequest: (email, config) =>
      dispatch(ForgetPasswordRequest(email, config)),
    onResetPasswordRequest: (data, config) =>
      dispatch(ResetPasswordRequest(data, config)),
  };
};

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
        password: "",
        ConfirmPassword: "",
      },
      mailSent: false,
      mailError: "",
      receivedToken: null,
      isLoading: false,
      isFormDone: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResetSubmit = this.handleResetSubmit.bind(this);
  }

  handleChange(event) {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit() {
    this.setState({ isLoading: true });
    this.setState({isFormDone : true})
    this.props.onforgetPasswordRequest(
      this.state.formData.email,
      this.props.config
    );
  }

  handleResetSubmit() {
    this.setState({ isLoading: true });
    var body = {
      ResetToken: resetToken,
      EmailAddress: resetEmail,
      ApplicationProfile: {
        emailAddress: resetEmail,
        PasswordHash: this.state.formData.password,
      },
    };

    this.props.onResetPasswordRequest(body, this.props.config);
  }

  componentDidMount() {
    var token = window.location.href.token;
    if (token !== "0" && token != null && token.length > 10) {
      var decodedString = atob(token);
      resetEmail = decodedString.split(":")[0];
      resetToken = decodedString.split(":")[1];
      if (
        resetEmail != null &&
        resetEmail.length > 0 &&
        resetToken != null &&
        resetToken.length > 0
      ) {
        this.setState({ receivedToken: token });
      }
      if (this.state.formData.email.length != 0) {
        if (this.state.isFormDone == false) {
            this.setState({
                isFormDone: true,
            })
        }
    }
    else {
        if (this.state.isFormDone == true) {
            this.setState({ isFormDone: false })
        }
    } 

    }

    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.formData.password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("passwordStrength", (value) => {
      var strongRegex = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$");
      if (strongRegex.test(value)) {
        return true;
      }
      return false;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sendMailerrorMessage != this.props.sendMailerrorMessage) {
      if (this.props.sendMailerrorMessage != "") {
        this.setState({ isLoading: false });
      }
    }
    if (prevProps.ForgotEmailSent != this.props.ForgotEmailSent) {
      if (this.props.ForgotEmailSent) {
        this.setState({ isLoading: false });
      }
    }
    if (this.state.formData.email.length != 0) {
      if (this.state.isFormDone == false) {
          this.setState({
              isFormDone: true,
          })
      }
  }
  else {
      if (this.state.isFormDone == true) {
          this.setState({ isFormDone: false })
      }
  } 
  }
  showMessageForgot = (msg, type) => {
    if (type == "success") {
      toast.success(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "show-invalid-text",
        closeButton: false,
        hideProgressBar: false,
        autoClose: 3000,
      });
    } else {
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "show-invalid-err",
        closeButton: false,
        hideProgressBar: false,
        autoClose: 3000,
      });
    }
  };
  render() {
    const { formData, submitted } = this.state;
    return (
      <div>
        <div>
          {/* <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              width: 100,
              textAlign: "left",
              margin: 10,
            }}
          >
            <img
              onClick={() => this.props.setPage(0)}
              src={require("../../assets/images/back.svg")}
            />
            <span
              style={{
                position: "absolute",
                paddingLeft: 5,
                fontFamily: "SFProRounded-regular",
                fontSize: "17px",
                lineHeight: "24pt",
                letterSpacing: "-0.3pt",
              }}
              onClick={() => this.props.setPage(0)}
            >
              Back
            </span>
          </div> */}
        </div>
        <div style={{ marginTop: 78 }}>
          {!this.props.ForgotEmailSent && this.state.receivedToken == null && (
            <Grid container spacing={16}>
              {/* <Grid item xs={3} sm={3}></Grid> */}
              <Grid item xs={11} sm={6} className="centerContent">
                <Logo logo={this.props?.config?.logoUrl} />
                <div>
                  <PageTitle
                    label={
                      <Typography
                        variant="h1"
                        style={{ letterSpacing: "0.41px" }}
                      >
                        {Translate({ id: "ForgetPassword.ForgotPassword" })}
                      </Typography>
                    }
                  />
                  <div className="subscriptionTagline subText m-t-15 m-t-xs-15">
                    <p>
                      {
                        <Typography
                          variant={"h6"}
                          style={{
                            letterSpacing: "0",
                            fontSize: "20px",
                            lineHeight: "28px",
                          }}
                        >
                          {Translate({
                            id: "ForgetPassword.ForgotPasswordMessage",
                          })}
                        </Typography>
                      }
                    </p>
                  </div>

                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    className="editForm"
                  >
                    <div className="">
                      <TextValidator
                        inputProps={{ maxLength: 100 }}
                        label={
                          <Typography variant="body1">
                            {Translate({ id: "Signup.Email/Username" })}
                          </Typography>
                        }
                        onChange={this.handleChange}
                        name="email"
                        value={formData.email}
                        validators={FORM.formItems.validatation}
                        className="errorbold  textfieldreset"
                        errorMessages={[
                          Translate({ id: "login.EmailRequired" }),
                          Translate({ id: "login.EmailValidFormat" }),
                        ]}
                      />
                    </div>
                    <div className="clear "> </div>
                    <div style={{ marginTop: 31.5 }}>
                      {this.state.isLoading ? (
                        <Spinner />
                      ) : (
                        <Button 
                         fullWidth
                          disabled={!this.state.isFormDone}
                          variant="contained"
                          type="submit"
                          color="secondary"
                          className="btn-default"
                          //style={{color:this.state.isFormDone ? "#ffffff" :"rgba(0, 0, 0, 0.26)"}}                       
                           >
                          {" "}
                          {
                            <Typography variant="button">
                              {Translate({
                                id: "ForgetPassword.PasswordReset",
                              })}
                            </Typography>
                          }{" "}
                        </Button>
                      )}
                    </div>
                    <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              width: 100,
              textAlign: "left",
              marginTop:10
            }}
          >  
          <Typography variant="subtitle2" style={{
            position: "absolute",
            fontFamily: "SFProRounded-regular",
            fontSize: "17px",
            lineHeight: "24pt",
            letterSpacing: "-0.3pt",
            
          }} color="primary" className="dynamiclinks" onClick={() => this.props.setPage(0)}>Back</Typography>
           {/* <span
          style={{
            position: "absolute",
            fontFamily: "SFProRounded-regular",
            fontSize: "17px",
            lineHeight: "24pt",
            letterSpacing: "-0.3pt",
            
          }}
          onClick={() => this.props.setPage(0)}
        >
          Back
        </span> */}
        </div>
                  </ValidatorForm>
                  {/* <div style={{ marginTop: 50 }}>
                  <Button
                    size="large"
                    onClick={() => this.props.setPage(0)}
                    variant="contained"
                    color="secondary"
                    className="button btn-default"
                  >
                    {<Typography variant="h5">Go Back</Typography>}
                  </Button>
                </div> */}
                </div>
              </Grid>
            </Grid>
          )}

          {this.props.ForgotEmailSent && this.state.receivedToken == null && (
            <React.Fragment>
              <div style={{ paddingTop: "40px" }}>
                <div className="marginbottom1">
                  <img src={require("../../assets/images/sentimage.svg")} />
                </div>
                <PageTitle
                  label={
                    <Typography variant="h1">
                      {Translate({ id: "ForgetPassword.Sent" })}
                    </Typography>
                  }
                ></PageTitle>

                <div style={{ marginTop: "-15px" }}>
                  <p className="centeralign">
                    <Typography variant="h6" className="text-gray worddown">
                      {Translate({ id: "ForgetPassword.SentMessage" })}
                    </Typography>
                  </p>
                </div>
              </div>

              {/* <div style={{ marginTop: 50 }}>
              <Button
                size="large"
                onClick={() => this.props.setPage(0)}
                variant="contained"
                color="secondary"
                className="button btn-default"
              >
                {<Typography variant="h5">Go Back</Typography>}
              </Button>
            </div> */}
            </React.Fragment>
          )}

          {this.state.receivedToken != null && (
            <div>
              <PageTitle
                label={Translate({ id: "ForgetPassword.EnterNewPassword" })}
              />
              <Grid container spacing={16}>
                {/* <Grid item xs={3} sm={3}></Grid> */}
                <Grid item xs={11} sm={6} className="centerContent">
                  <div>
                    <ValidatorForm
                      ref="form"
                      onSubmit={this.handleResetSubmit}
                      className="editForm"
                    >
                      <br />
                      <TextValidator
                        inputProps={{ maxLength: 100 }}
                        type="Password"
                        fullWidth
                        label={Translate({ id: "Signup.Password" })}
                        onChange={this.handleChange}
                        name="password"
                        value={formData.password}
                        className="errorbold"
                        validators={["required", "passwordStrength"]}
                        errorMessages={[
                          Translate({ id: "login.PasswordRequired" }),
                          Translate({ id: "ForgetPassword.Regex" }),
                        ]}
                      />
                      <TextValidator
                        inputProps={{ maxLength: 100 }}
                        type="Password"
                        fullWidth
                        label={Translate({
                          id: "ForgetPassword.ConfirmPassword",
                        })}
                        onChange={this.handleChange}
                        name="ConfirmPassword"
                        value={formData.ConfirmPassword}
                        validators={["isPasswordMatch"]}
                        className="errorbold"
                        errorMessages={[
                          Translate({ id: "ForgetPassword.PasswordMismatch" }),
                        ]}
                      />
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className="btn-default"
                      >
                        {
                          <Typography variant="button">
                            {Translate({
                              id: "ForgetPassword.SaveResetPassword",
                            })}
                          </Typography>
                        }
                      </Button>
                    </ValidatorForm>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
        <ToastContainer icon={false}  transition={Slide} hideProgressBar={false}/>
        <div>
          {this.props.sendMailerrorMessage
            ? this.showMessageForgot(this.props.sendMailerrorMessage, "error")
            : ""}
          {this.props.resetPasswordSuccessMessage
            ? this.showMessageForgot(
                this.props.resetPasswordSuccessMessage,
                "success"
              )
            : ""}
          {this.props.resetPassworderrorMessage
            ? this.showMessageForgot(this.props.resetPassworderrorMessage, "error")
            : ""}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
