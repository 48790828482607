export const authenticatePlatformUserAPI = "/oauth/authenticateplatformuser";
export const thirdPartyUserOnWexerApi = "oauth/login"
export const creatThirdParyUserOnWexerAPI = "/oauth/signup";
export const LoginAPI = "/oauth/login"

//export const authenticatePlatformUserAPI = "/oauth/authenticatewebuser/";
export const consentAPI = "/api/v2/consent";
export const userConsentAPI = "/api/v2/users/consent";
export const saveUserConsentAPI = "/api/v2/users/consent";

export const sendContactUsMail = "/api/v1/ContactUs";

export const forgetPassword = "api/v2/forgotpassword";
export const forgetPasswordViaAdestra = "/api/v1/campaign/sendresetpasswordemail"
export const resetPassword = "api/v2/resetpassword";



export const getSubscriptionProductAPI = "/api/v2/products";
export const getUserSubscriptionAPI = "/api/v1/users/subscriptions";
export const FreeTrailAPI = "/api/v1/payments/trial";
export const purchaseSubscriptionAPI = "/api/v1/payments/subscription";
export const cancelUserSubscriptionAPI = "/api/v1/user/subscription";
export const resumeUserSubscriptionAPI = "/api/v1/payments/subscription";
export const getFreeAccessSubscription = "api/v2/subscription";
export const verifyFreeAccessCode = "api/v1/verify/freeaccess/";

export const createPlatformUserAPI = "/api/v1/createplatformuser";


export const subscribeAdestra = "/api/v1/users/marketing/"
export const confirmationEmail = "/api/v1/campaign/sendemail/"

export const newLoginAPI = "api/v1/identity/login";



export const BASE_APIURL = {
    test: "https://ff-int.api.wexer.com/",
    stage: "https://stage.api.wexer.com/",
    prod: "https://api.wexer.com/",
}
