import React, { useEffect, useState } from "react";
import "./theme/styles/App.scss";
import Layout from "./containers/Layout/Layout";
import { Grid } from "../node_modules/@material-ui/core";

import { injectIntl } from "react-intl";
import SignPage from './containers/SignIn/SignIn'
import SignUpPage from './containers/Signup/Signup'
import ResetPassword from './containers/User/ForgetPassword'
import { resetErrorMessage } from "./store/actions/auth";
import { connect } from "react-redux";

const App = ({...props}) => {
  const [config, setConfig] = useState(null);
  const [dafaultPage, setPage] = useState(0)
  useEffect(() => {
    let param = window.location.href;

    let customParam = param.split("?")[1]
    if (customParam) {
      customParam = customParam.split("&")
      if (Array.isArray(customParam) && customParam.length > 0) {
        const newState = {};
        customParam.forEach(element => {
          let elm = element.split("=")
          newState[elm[0]] = elm[1].includes('%23') ? elm[1].replace('%23', '#') : elm[1];
        });
        setConfig(newState);
      }
    }
  }, [])


  useEffect(() => {
    if (config) {
      document.documentElement.style.setProperty('--main-primary-color', config?.primaryColor);
      document.documentElement.style.setProperty('--hover-secondary-color', config?.secondaryColor);
      document.documentElement.style.setProperty('--hover-color', config?.accentColor);
      localStorage.setItem('TenantId',config.tenantId)
    }
  }, [config])


  const screenRenderer = (param) => {
   props.resetErr();
    switch (param) {
      case 0:
        return <SignPage config={config} setPage={setPage} />
        break;
      case 1:
        return <SignUpPage config={config} setPage={setPage} />
        break;
      case 2:
        return <ResetPassword setPage={setPage} config={config} />
        break;
      default:
        return <SignPage config={config} setPage={setPage} />
    }

  }

  return (

    <React.Fragment>
      <div className="App">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <Layout>
              {config!==null&&screenRenderer(dafaultPage)}
            </Layout>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>

  );

}

const mapDispatchToProps = dispatch => {
  return {
      resetErr :()=>dispatch (resetErrorMessage() )
  };
};

//export default App;
export default connect(
  null,
  mapDispatchToProps
)(injectIntl(App));
