import React, { Component,Fragment } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { Grid, Typography, Input } from "@material-ui/core";
import { connect } from "react-redux";
import { AuthenticateUser,resetErrorMessage } from "../../store/actions/auth";
import { Translate } from "../../utils/Translate";
import Fade from "@material-ui/core/Fade";
import Spinner from "../../components/spinner/spinner";
import CustomDialog from '../../components/Modal/Modal';
import { createStateObj } from '../../utils/utilityfunctions';
import FormGenerator from '../../components/Forms/FormGenerator'
import { AuthenticateThirdPartyUser } from '../../assets/ClubManager/action'
import environment from "../../assets/ClubManager/config";
import Logo from "../SignIn/Logo"
import { ToastContainer, toast ,Slide} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const config = require('../../assets/config.json');

const tenantSepecificUIObject = require('../../assets/ClubManager/tenantConfig.json');
const FORM = tenantSepecificUIObject.signInFormFeilds

const formFieldState = createStateObj(FORM)

class SignInPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                //  ...formFieldState
                email: "",
                password: "",
                barcodeId: ""
            },
            isFormDone: false,
            adestraLinkParam: false,
            showPopUPforSSO: false,
            loading: false
        };
        this.formItems = this.formItems.bind(this);
    }

    formItems = (event) => {
        this.props.resetErr();
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    getResetPasswordURL() {
        return environment.RESET_PASSWORD;
    }


    handleSubmit = () => {

        this.props.onAuthenticate(
            this.state.formData,
            this.props.config,
        );
    }

    handleMemberShipDialog = () => {

        if (config.UpgradeRedirect != null) {
            window.open(config.UpgradeRedirect, '_blank')
        }

        this.setState({ showPopUPforSSO: false })
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        if (config.msalSsoSupport) {
            this.props.AuthenticateThirdPartyUser();
        }
        if (config.embeddedConfig && config.embeddedConfig.isEmbedded == true && this.props.embeddedConfig) {
            this.setState({ formData: { email: "", password: "", barcodeId: this.props.embeddedConfig.token } })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.errorMessage != this.props.errorMessage) {
            if (this.props.errorMessage != undefined) {
                this.setState({ submitted: false });
            }
        }
        if (this.state.formData.email.length != 0 && this.state.formData.password.length != 0) {
            if (this.state.isFormDone == false) {
                this.setState({
                    isFormDone: true,
                })
            }
        }
        else {
            if (this.state.isFormDone == true) {
                this.setState({ isFormDone: false })
            }
        }
        if (prevProps.authData !== this.props.authData) {
            if (this.props.authData) {
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify(this.props.authData))
                 }
            }
        }


        if (prevProps.loading !== this.props.loading ) {
            if (this.props.authData) {
                  var handle =  setTimeout(function() { this.setState({ loading: this.props.loading })
                }, 6000);
                  clearTimeout(handle);
            }else{
                this.setState({ loading: this.props.loading })
            }
        }
    }

    gotoSignUpPage = () => {
        this.props.setPage(1);
    }
    showMessage = (msg) => {
    
        toast.error(msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            toastId:'show-invalid',
            closeButton:false,
            hideProgressBar:false,
            autoClose:3000,
          })
        
    }

    render() {
        const { formData } = this.state;
      
        return (
            <React.Fragment>
            { this.state.loading ? (
                <div className="clipLoaderDiv" >
                    <Fade in={this.props.loading}
                        style={
                            {
                                transitionDelay: this.props.loading ? "800ms" : "0ms",
                                backgroundColor: "white",
                            }
                        }
                        unmountOnExit >
                        <Spinner backColor="white" />
                    </Fade>
                </div>) : (
                !config.msalSsoSupport && 
                <Grid container >
                    <div className="browse-collection-menu padding16">
                        {(config.canUnsubcribedUsersLogin == false && this.props.isSubscriptionActive == false) || this.state.showPopUPforSSO ?
                            <CustomDialog
                                open={true}
                                title={Translate({ id: "Subscription.UpgradeMembership" })}
                                heading={Translate({ id: "Subscription.UpgradeMessage" })}
                            >
                                <div className="text-center padding16">
                                    <Button size="small" type="submit" color="secondary"
                                        className="button btn-default" variant="contained"
                                        onClick={this.handleMemberShipDialog}
                                        style={{ marginTop: "60px" }}
                                    >
                                        <Typography variant="h5">
                                            {Translate({ id: "Subscription.Ok" })}
                                        </Typography>
                                    </Button>
                                </div>
                            </CustomDialog>
                            : null}
                        <Grid item>
                            <div style={{ marginTop: 78}}>
                                <Logo logo={this.props?.config?.logoUrl}/>
                                <div className="subscriptionTagline subText" >
                                    <Typography variant="h6" > {
                                        Translate({
                                            id: "login.LoginSubTitle",
                                            values: { clubName: localStorage.getItem("ClientDisplayName") },
                                        })
                                    }
                                    </Typography>
                                </div>
                            </div>
                            {/* {
                                this.props.showAccessDeniedDialog ? < div className="deniedAccess" > {Translate({ id: "login.DeniedAccessText" })} 
                                <br /> {Translate({ id: "login.DeniedAccessText2" })} </div> : <div> <span className="errorMessageAlert" > {this.props.errorMessage} </span> </div>
                            }  */}

                                    <ValidatorForm
                                        className="editForm loginInputs"
                                        ref="form"
                                        onSubmit={this.handleSubmit} >

                                <div className="marginFormTop" >

                                    <FormGenerator
                                        FORM={FORM}
                                        formData={formData}
                                        callback={this.formItems}
                                        isError={this.state.isError}
                                    />

                                    {
                                        config.showForgetPasswordPage === false ? (null) : (
                                            <div style={{ marginTop: "7px" }}>{config.willRedirectToThirdPartyUrlForPasswordReset === true ? (
                                                <a href={this.getResetPasswordURL()}
                                                    className="dynamiclinks"
                                                    target="_blank"
                                                    style={
                                                        { alignItems: "flex-start", float: "left" }} >
                                                    <Typography variant="subtitle2" color="secondary"
                                                        style={
                                                            {fontFamily: "SFProRounded-regular",fontSize:"17px",lineHeight: "24pt",
                                                            letterSpacing: "-0.3pt",alignItems: "flex-start", float: "left" ,  cursor: 'pointer' }} >
                                                        {Translate({ id: "login.ForgotPassword" })}
                                                    </Typography>
                                                </a>

                                            ) :
                                                (<p onClick={() => this.props.setPage(2)}
                                                    className="dynamiclinks makeTextClickable"
                                                    style={
                                                        { fontSize:"17",lineHeight: "24pt",
                                                        letterSpacing: "-0.3pt",alignItems: "flex-start", float: "left" }} >
                                                    <Typography variant="subtitle2" color="primary" className="dynamiclinks"
                                                        style={
                                                            { fontFamily: "SFProRounded-regular",fontSize:"17px",lineHeight: "24pt",
                                                            letterSpacing: "-0.3pt"}} >
                                                        {Translate({ id: "login.ForgotPassword" })}
                                                    </Typography>
                                                </p>
                                                )}
                                            </div>
                                        )}
                                    <div className="clear " >  </div> <div  >
                                        <Button fullWidth
                                            disabled={!this.state.isFormDone}
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            className="btn-default"
                                        >
                                            <Typography variant="button" > {Translate({ id: "layout.SignIn" })} </Typography>
                                        </Button>
                                    </div>
                                    {this.props?.config?.canUserSignUp == "true" && <div  style={
                                                            { fontSize:"17",lineHeight: "24pt",
                                                            letterSpacing: "-0.3",float:"left",marginTop:"13px"}} 
                                                            >
                                        <Typography variant="caption" style={
                                                            { fontSize:"17",height:24,lineHeight: "24pt",
                                                            letterSpacing: "-0.3",float:"left"}} color="secondary" > {Translate({ id: "login.LoginNoAccountMsg" })} </Typography>
                                        <Typography variant="caption" style={
                                                            { fontSize:"17",height:24,lineHeight: "24pt",
                                                            letterSpacing: "-0.3",float:"left"}}  className="makeTextClickable" color="secondary" onClick={this.gotoSignUpPage}>&nbsp;{Translate({ id: "Signup.Title" })} </Typography>
                                    </div>}
                                    {/* <p className="subText ft-12 m-t-15 m-t-xs-15" >
                                        <span >
                                            <Typography variant="caption"
                                                className="text-gray" > {Translate({ id: "login.Disclaimer" })} </Typography>  </span> </p>
                                                 */}
                                                 </div> </ValidatorForm>

                                                 
                        </Grid>
                    </div>
                   </Grid>
                   )
                                                }
                   {
            this.props.showAccessDeniedDialog ? < div className="deniedAccess" > {Translate({ id: "login.DeniedAccessText" })} 
            <br /> {Translate({ id: "login.DeniedAccessText2" })} </div> : this.props.errorMessage ? this.showMessage(this.props.errorMessage ) :""
            // <div style={{position:'absolute',bottom:21,left:15,width:'91%'}}> <span className="errorMessage" > {this.props.errorMessage} </span> </div> :""
        } 
        <ToastContainer icon={false} transition={Slide}/>
         </React.Fragment>
        );
       
    }
}
const mapStateToProps = state => {
    return {
        loading: state.auth.auth_loading,
        errorMessage: state.auth.errorMessage,
        isAuthenticated: state.auth.isAuthenticated,
        showAccessDeniedDialog: state.auth.showDialog,
        deepLinkPayload: state.auth.deepLinkPayload,
        embeddedConfig: state.auth.embeddedConfig,
        authData: state.auth.authData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthenticate: (userData, config) =>
            dispatch(AuthenticateUser(userData, config)),
        AuthenticateThirdPartyUser: () => dispatch(AuthenticateThirdPartyUser()),
        resetErr :()=>dispatch (resetErrorMessage() )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignInPage);