   import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import {Typography,Grid, Button} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import { Translate } from "../utils/Translate"
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';


class CheckboxValidatorElement extends ValidatorComponent {

    render() {

        const { errorMessages, validators, requiredError, value, label, ...rest } = this.props;

        return (
            <Grid className="tnctext" >
            <div  >
                 <Checkbox
                     icon={
                     <RadioButtonUncheckedRoundedIcon  className="termsIcon" />
                    }
                     checkedIcon={
                     <RadioButtonCheckedRoundedIcon  className="termsIcon" />
                    }    
                    {...rest}
                    ref={(r) => { this.input = r; }}
                />
                </div>
            
                <div >
                    <Typography onClick={this.props.tncClick}
                    color = "secondary"
                  variant="h3"
                  style={{marginTop:"7px"}}
                    >
                        {this.props.label}
                 </Typography>
                   
                </div>
                <p className='Error'>{this.errorText()}</p>
            
            </Grid>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div className="MuiFormHelperText-root-250 MuiFormHelperText-error-251" >
                {this.getErrorMessage()}
            </div>
        );
    }}

 

export default CheckboxValidatorElement;
