import { create } from 'apisauce'
import { BASE_APIURL } from './store/APIConstants';
const testApi = create({
    baseURL: BASE_APIURL.test,
    // Accept: 'application/json',
    headers: {
         'content-type': 'application/json',
         'Cache-Control' : 'no-cache'
    }
});


testApi.addRequestTransform(request => {
   
        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        request.headers['TenantId']=localStorage.getItem('TenantId');
   
})
const stageApi = create({
    baseURL: BASE_APIURL.stage,
    // Accept: 'application/json',
    headers: {
         'content-type': 'application/json',
         'Cache-Control' : 'no-cache'
    }
});


stageApi.addRequestTransform(request => {
   
        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        request.headers['TenantId']=localStorage.getItem('TenantId');
   
})
const prodApi = create({
    baseURL: BASE_APIURL.prod,
    // Accept: 'application/json',
    headers: {
         'content-type': 'application/json',
         'Cache-Control' : 'no-cache'
    }
});


prodApi.addRequestTransform(request => {
   
        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        request.headers['TenantId']=localStorage.getItem('TenantId');
   
})

const userApi = {
    test: testApi,
    stage: stageApi,
    prod: prodApi
}

export default userApi;