import React, { Component } from "react";

import { connect } from "react-redux";

class Logo extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        <div><img 
        src={this.props.logo ? this.props.logo :require("../../assets/images/clientLogo.svg")}
        alt="logo"
        style={{ marginVertical: 30,
        alignSelf: 'center',
        resizeMode: 'contain',
        width:'45%',
        paddingBottom:30}}
      /></div>

    );
  }
}

const mapStateToProps = state => {
  return {

    isAuthenticated: state.auth.token,
    notifyUser: state.auth.notifyUser,


  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logo);