import React, { Component } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import PageTitle from "../../components/PageTitle";
import { Translate } from "../../utils/Translate";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  GetConsentDetail,
  createUserWrapper,
} from "../../store/actions/auth";
import { connect } from "react-redux";
import { getTNCConsent } from "../../store/selectors/Consents";
import CheckboxValidatorElement from "../../ValidatorComponent/CheckboxValidatorElement";
import { ConsentAction } from "../../utils/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomDialog from "../../components/Modal/Modal"
import InputText from '../../ValidatorComponent/TextBoxValidatorComponent';
import { Typography } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import logger from "../../utils/logger";
import { createStateObj } from '../../utils/utilityfunctions';
import Logo from "../SignIn/Logo"

import FormGenerator from '../../components/Forms/FormGenerator'
import { GetFreeSubscription } from "../../store/actions/subscription";

var config = require("../../assets/config.json");

const tenantSepecificUIObject = require('../../assets/ClubManager/tenantConfig.json');
const FORM = tenantSepecificUIObject.signUpFormFeilds

const formFieldState = createStateObj(FORM)

class SignupPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        ...formFieldState,
        tnc: "",
        mktg: "",
        freeAccessCode: "",
        tncConsent: false,
        purchaseCallMade: false,
      },
      submitted: false,
      authRedirect: "",
      tncDialog: false,
      tncHtml: null,
      isError: false,
      isFormFilled: false,
      subscriptionModel:this.props?.config&&this.props.config?.subscriptionModel
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData, [name]: value
        }
      }
    })
  }

  handleCheckChange(event) {
    logger("checkbox")

    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData, tncConsent: !this.state.formData.tncConsent
        }
      }
    })
  }

  OpenTncDialog = (e) => {

    this.setState({ tncDialog: true });
  };

  handleDialogClose = () => {

    this.setState({ tncDialog: false });
  };

  acceptTnc = () => {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData, tncConsent: true
        }
      }
    })
    this.OpenTncDialog()

  }



  handleSubmit() {
    if (this.state.submitted == false) {
      this.setState({ submitted: true, isError: false }, () => {

        let userdata = {
          // EmailAddress: this.state.formData.email,
          // PasswordHash: this.state.formData.password,
          // FirstName: this.state.formData.firstName,
          // LastName: this.state.formData.lastName,
        }


        for (const i in formFieldState) {
          userdata[i] = this.state.formData[i]
        }

        var body = {
          UserDetail: {
            ApplicationProfile: {
              ...userdata,
              countrycode: "GB",
            },
          },
          ConsentRequest: [
            {
              ConsentTag: this.props.tnConsent.tag,
              Action: this.state.formData.tncConsent
                ? ConsentAction.Accepted
                : ConsentAction.Rejected,
            },
          ]
        };
        if (config.AccessCodeMandatory||this.state.subscriptionModel==='FAC') {
          body.AccessCode = this.state.formData.freeAccessCode;
        }
        this.props.createPlatformUser(body, this.state.formData.freeAccessCode, this.props.config);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.errorMessage != this.props.errorMessage) {
      if (this.props.errorMessage != undefined) {
        this.setState({ submitted: false });
      }
    }
    if (prevProps.tnConsent.label !== this.props.tnConsent.label) {
    }
    var ControledClass = document.getElementsByClassName("controledClass");
    if (ControledClass.length > 0) {
      ControledClass[0].addEventListener("click", this.OpenTncDialog);
    }
    if (
      this.props.consents != null &&
      prevProps.consents != this.props.consents
    ) {
      for (var i = 0; i < this.props.consents.length; i++) {
        if (this.props.consents[i].policy == "tnc") {
          this.setState({ tncHtml: this.props.consents[i].policyHtml });
        }
      }
    }

    if (prevState.formData !== this.state.formData) {
      if (this.state.formData.tncConsent == true) {
        let formFilled = true
        for (const key in formFieldState) {
          if (this.state.formData[key].length === 0) {
            formFilled = false
          }
        }
        if (formFilled) {
          if (this.state.isFormFilled == false) {
            this.setState({ isFormFilled: true })
          }
        }
        else {
          if (this.state.isFormFilled == true) {
            this.setState({ isFormFilled: false })
          }
        }
      } else {
        if (this.state.isFormFilled == true) {
          this.setState({ isFormFilled: false })
        }
      }
    }
    if (this.state.subscriptionModel==='FAC'&&this.props.subscriptionStatus !== null && (prevProps.subscriptionStatus !== this.props.subscriptionStatus)&&this.props.authData) {
      //  if (prevProps.authData !== this.props.authData) {
        if (this.props.authData) {
            if (window.ReactNativeWebView) {
              let data={...this.props.authData,...{signinAfterSignUP:true}}
              window.ReactNativeWebView.postMessage(JSON.stringify(data))
            }
        }
      //  }
    }
    else if(this.state.subscriptionModel!=='FAC'){
      if (prevProps.authData !== this.props.authData) {
        if (this.props.authData) {
            if (window.ReactNativeWebView) {
              let data={...this.props.authData,...{signinAfterSignUP:true}}
              window.ReactNativeWebView.postMessage(JSON.stringify(data))
            }
        }
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.loadConsentDetail(this.props.config);
    ValidatorForm.addValidationRule("required", value => value);
    ValidatorForm.addValidationRule("passwordStrength", value => {
      var strongRegex = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$");
      if (strongRegex.test(value)) {
        return true;
      }
      else if (this.state.formData.PasswordHash.length == 0) {
        return true;
      }
      return false;
    });


  }

  gotoSignInPage = () => {
    this.props.setPage(0);
  }

  render() {
    const { formData, submitted } = this.state;
    let authRedirect = null;
    if (this.props.userCreated) {
      if (this.props.isAuthenticated && !this.state.purchaseCallMade) {
        if (
          this.props.verifiedAccessCode != "" &&
          this.props.verifiedAccessCode != null
        ) {
          this.props.onGetFreeSubscription(this.props.verifiedAccessCode,this.props.config);
        } else {
          let productTag = "";
          let planTag = "";
          if (this.props.trialPlan != null && this.props.trialPlan.length > 0) {
            productTag = this.props.trialPlan[0].productId;
            planTag = this.props.trialPlan[0].tag;
            this.props.onStartFreeTrail(planTag, productTag);
          }
        }

        this.setState({ purchaseCallMade: true });

      }
    }


    return (
      <Grid container>

        <div className="browse-collection-menu padding16">

          <Grid item>
            <div className="m-t-55" style={{ marginTop: 78}}>
              {this.state.subscriptionModel!=="FAC"&&<PageTitle label={Translate({ id: "Signup.Title" })} TiltleBackColor="white" />}
             {this.state.subscriptionModel=="FAC"&& <Logo logo={this.props?.config?.logoUrl}/>}
              <div className="subscriptionTagline subText">
                <Typography variant="h6">
                  {Translate({
                    id: this.state.subscriptionModel!=="FAC"?"Signup.SignUpSubTitle":"Signup.SignUpSubTitleFAC",
                    values: { clubName: localStorage.getItem("ClientDisplayName") },
                  })}
                </Typography>
              </div>
            </div>

          </Grid>

          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
            className="editForm signupInputs"
            onError={() => this.setState({ isError: true })}
          >

            <span className="errorMessage">{this.props.errorMessage}</span>
            <div className="marginFormTop">

              <FormGenerator
                FORM={FORM}
                formData={formData}
                callback={this.handleChange}
                isError={this.state.isError}
              />
              <br />

              {(config.AccessCodeMandatory === true||this.state.subscriptionModel=="FAC") && (
                <InputText
                  type="text"
                  label={this.state.subscriptionModel=="FAC"?Translate({ id: "Signup.AccessCode" }):Translate({ id: "Signup.FreeAccessCode" })}
                  callback={this.handleChange}
                  name="freeAccessCode"
                  value={formData.freeAccessCode}
                  validators={["required"]}
                  errorMessages={[
                    Translate({ id: "Signup.FreeAccessCodeRequired" }),
                  ]}
                />
              )}



              <div className="floatLeft termsMargin ">

                <CheckboxValidatorElement
                  name="tncConsent"
                  color="secondary"
                  label={Translate({ id: "Signup.tncText" })}
                  onChange={this.handleCheckChange}
                  validators={["required"]}
                  tncClick={this.OpenTncDialog}
                  errorMessages={Translate({ id: "TnCPage.tncAccept" })}
                  checked={formData.tncConsent}
                  value={formData.tncConsent}

                />

              </div>

              <div className="clear" />
              <br />
              <div>
                {!this.props.loading ? (
                  <Button
                    fullWidth
                    disabled={!this.state.isFormFilled}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className="btn-default"
                    // style={{ width: "100%", height: 64, borderRadius: 35, marginBottom: "15px" }}     
                    >
                    <Typography variant="button" style={{ color: "secondary" }}>
                      {Translate({ id: "Signup.Title" })}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    disabled
                    variant="contained"
                    color="secondary"
                    className="btn-default"
                    // style={{ width: "100%", height: 64, borderRadius: 35, marginBottom: "15px" }}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                    >
                      <Grid item>
                        <Typography variant="button" style={{ color: "secondary" }}>
                          {Translate({ id: "Signup.PleaseWait" })}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <CircularProgress
                          style={{
                            marginLeft: "5px",
                            color: "#fff",
                            height: "2em",
                            width: "2em",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Button>
                )}
              </div>
              {this.state.subscriptionModel==="FAC"&&
                <div style={{ fontSize:"17",lineHeight: "24pt",letterSpacing: "-0.3",float:"left",marginTop:"13px"}}>
                  <Typography variant="caption" style={{ fontSize:"17",height:24,lineHeight: "24pt",letterSpacing: "-0.3",float:"left"}} color="secondary">{Translate({ id: "Signup.AlreadyHaveAnAccount" })}</Typography>
                    <Typography variant="caption" style={{ fontSize:"17",height:24,lineHeight: "24pt",
                      letterSpacing: "-0.3",float:"left"}}  className="makeTextClickable" color="secondary" onClick={this.gotoSignInPage}>&nbsp;{Translate({ id: "login.Title" })} </Typography>
                </div>
            }
            { this.state.subscriptionModel!=="FAC"&&<div className="ft-12 m-t-15 m-t-xs-15">
                <Typography variant="caption" className="text-gray" > {Translate({ id: "Signup.AlreadyHaveAnAccount" })} </Typography>
                <Typography variant="caption" className="makeTextClickable" color="secondary" onClick={this.gotoSignInPage}> {Translate({ id: "login.Title" })} </Typography>
              </div>}
             {this.state.subscriptionModel!=="FAC"&& <div className="subText ft-12 m-t-15 m-t-xs-15">
                <Typography variant="caption" style={{ color: "#8e8e93" }}>
                  {Translate({ id: "login.Disclaimer" })}
                </Typography>
              </div>}
            </div>
            <br />
            <br />
          </ValidatorForm>
          <CustomDialog
            open={this.state.tncDialog}
            title={Translate({ id: "TnCPage.dialogText" })}
            cancelicon={true}
            isContentScrollable={true}
            handleDialogClose={this.handleDialogClose}
            acceptedCallback={this.acceptTnc}
          >
            <div>
              {this.state.tncHtml ? ReactHtmlParser(this.state.tncHtml) : null}
            </div>
          </CustomDialog>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    consents: state.auth.consents,
    tnConsent: getTNCConsent(state),
    errorMessage: state.auth.errorMessage,
    userCreated: state.auth.userCreated,
    isAuthenticated: state.auth.token !== "",
    verifiedAccessCode: state.auth.VerifiedAccessCode,
    loading: state.auth.loading,
    deepLinkPayload: state.auth.deepLinkPayload,
    embeddedConfig: state.auth.embeddedConfig,
    authData: state.auth.authData,
    subscriptionStatus: state.subscription.subscriptionStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadConsentDetail: (config) => dispatch(GetConsentDetail(config)),
    createPlatformUser: (userData, freeAccessCode, config) =>
      dispatch(createUserWrapper(userData, freeAccessCode, config)),
      onGetFreeSubscription: (code,config) => dispatch(GetFreeSubscription(code,config)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupPage);
